import {useState} from 'react';
import Product from '../components/Product';
import products from '../products'; 
import './ThirdSection.css';

function SecondSection() {
	const [selectedProductId, setSelectedProductId] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transition, setTransition] = useState(false);

  const handleProductClick = (actualId) => {
	  const id = actualId -1
	  console.log("id:",id)
	  console.log("selectedProductId:",selectedProductId)
	  
	  if(id == 0 && selectedProductId==products.length-1){
		  handleNext()
	  }else if(id==products.length-1 && selectedProductId==0){
		  handlePrev()
	  }else if(id>selectedProductId){
		  handleNext()
	  }else if(id<selectedProductId){
		  handlePrev()
	  }
	  else
		setSelectedProductId(id);
  };

  const handleNext = () => {
    setTransition(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % products.length);
      setTransition(false);
	setSelectedProductId((selectedProductId+1)%products.length);
  };

  const handlePrev = () => {
    setTransition(true);
    setCurrentIndex((prevIndex) =>{console.log(prevIndex + "|" + (prevIndex === 0 ? products.length - 1 : prevIndex - 1))
	return prevIndex === 0 ? products.length - 1 : prevIndex - 1;}
      );
      setTransition(false);
	
	setSelectedProductId(selectedProductId === 0 ? products.length - 1 : selectedProductId-1);
  };

  const visibleProducts = products.slice(currentIndex, currentIndex + 3);
   if (visibleProducts.length < 3) {
    visibleProducts.push(...products.slice(0, 3 - visibleProducts.length));
  }
	
  return (
    <div id="third-section" className="third-section">
      <h1 className="section-title">Finely selected for Tobacco connoisseurs</h1>
	  
	  
	  
	  <div className={`product-carousel ${transition ? 'transition' : ''}`} >
	  <button className="carousel-button left" onClick={handlePrev}>
        &lt;
      </button>
      <div className="products">
        {visibleProducts.map((product, index) => (
          <Product
            key={product.id}
            product={product}
            isSelected={product.id === (selectedProductId+1)}
            onClick={handleProductClick}
          />
        ))}
      </div>
      <button className="carousel-button right" onClick={handleNext}>
        &gt;
      </button>
	  </div>
	  
	   
    </div>
  );
}

export default SecondSection;