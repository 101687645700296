import React, { useState } from 'react';
import VideoBackground from '../components/VideoBackground';
import './FirstSection.css';

function FirstSection() {
	const [isVideoLoading, setIsVideoLoading] = useState(true);
	
	const toggleToFalse = () => {
		setIsVideoLoading(false);
	}
	
  return (
    <div className="first-section">
      <VideoBackground isLoading={isVideoLoading} onLoad={toggleToFalse} />
	  {isVideoLoading && <div className="loader"></div>}
    </div>
  );
}

export default FirstSection;