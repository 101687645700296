import React from 'react';
import './SecondSection.css';

function SecondSection() {
  return (
    <div id="second-section" className="second-section">
	  <span>
		<img src='logo-text.png' height={40}/>
		<h1 className="section-title">Welcome to Duke's Hall</h1>
		<h3 className="section-title">An enriching & savoury experience</h3>
	  </span>
    </div>
  );
}

export default SecondSection;