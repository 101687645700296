import React, { useRef } from 'react';
import { FaEnvelope, FaShareAlt, FaBars } from 'react-icons/fa';
import './AppBar.css';

function AppBar() {
	const inputRef = useRef(null);
	
	const uncheck = ()=>{
		inputRef.current.checked = false
	}
	
  return (
    <div className="app-bar">
      <div className="menu-icon-div">
        <input ref={inputRef} className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon"/>
		<label for="menu-icon"></label>
	  
	  <div className="nav"> 		
  		<ul className="pt-5">
  			<li><a href="#">Health</a></li>
  			<li><a href="#">News</a></li>
  			<li><a onClick={uncheck} href="#fourth-section">Contact</a></li>
  			<li><a href="#">About</a></li>
  		</ul>
	  </div>
        <span>Menu</span>
      </div>
	  
      <div className="logo">
        <img src="logo.png" alt="Logo" width={200} />
      </div>
	  
      <div className="cta-icons">
        <div className="icon">
		  <span>Mail</span>
          <a href="mailto:contact@dukesvapes.com"><FaEnvelope size={18} className="fa-icon"  /></a>
        </div>
        <div className="icon">
          <span>Share</span>
		  <FaShareAlt size={18} className="fa-icon" />
        </div>
      </div>
    </div>
  );
}

export default AppBar;