import React from 'react';
import './Product.css';

const Product = ({ product, isSelected, onClick }) => {
  return (
    <div
      className={`product ${isSelected ? 'selected' : 'notSelected'}`}
      onClick={() => onClick(product.id)}
    >
      <div className="product-container">
		<div className="product-circle"/>
        <img className="product-image" src={product.imageUrl} alt={product.name} height={150} />
      </div>
	  
      <div className="product-info">
        <h2 className="product-name">{product.name}</h2>
        {isSelected && (
          <div className="product-details">
            <p className="price">${product.price}</p>
            <p>{product.description}</p>
          </div>
        )}
      </div>
	  
    </div>
  );
};

export default Product;