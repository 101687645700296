const products = [
  {
    id: 1,
    name: 'Coffee Pairing',
    price: 12,
    imageUrl: '/prod.png',
    description: 'A delicate blend between tobacco flavours and creamy nutty desserts perfect pairing for coffee as well as an all day vape. ',
	outOfStock: false,
  },
  {
    id: 2,
    name: 'Morning Muffins',
    price: 12,
    imageUrl: 'prod.png',
    description: 'Not Available',
	outOfStock: true,
  },
  {
    id: 3,
    name: 'Afternoon Donuts',
    price: 12,
    imageUrl: 'prod.png',
    description: 'A thoroughly researched & crafted mixture for the sole purpouse of having an authentic Glazed Doughnut taste.',
	outOfStock: false,
  },
  {
    id: 4,
    name: 'Lake Cabin',
    price: 12,
    imageUrl: 'prod.png',
    description: 'Not Available',
	outOfStock: true,
  },
  {
    id: 5,
    name: 'Midnight Cookie',
    price: 12,
    imageUrl: 'prod.png',
    description: 'Not Available',
	outOfStock: true,
  },
  {
    id: 6,
    name: 'Brunch Cake',
    price: 12,
    imageUrl: 'prod.png',
    description: 'Not Available',
	outOfStock: true,
  },
  // Add more products as needed
];

export default products;
