import React, { useEffect, useRef } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import './VideoBackground.css';

function VideoBackground({ isLoading, onLoad }) {
	const videoRef = useRef(null);
	
	useEffect(() => {
    const handleLoadedData = () => {
      onLoad();
    };

    const video = videoRef.current;
    video.addEventListener('loadeddata', handleLoadedData);

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData);
    };
  }, [onLoad]);
  
  return (
    <div className="video-background">
	
      <video ref={videoRef} autoPlay loop muted>
        <source src="bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
	  
	  <a href="#second-section" className="arrow-down">
        <FaArrowDown size={36} />
      </a>
	  
    </div>
  );
}

export default VideoBackground;